(function() {
    if(getCookie('user_location') === false) {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(success, error);
            // Test à 35490 Gahard, plus proche de Rennes
            // setCookie('user_location', '48.06771469116211,-1.7211861610412598', 30);
            //  console.log(getCookie('user_location'));
        } else { 
            console.log("Geolocation is not supported by this browser.");
        }
    }

    function success (pos) {
        let crd = pos.coords;
        let geoString = `${crd.latitude},${crd.longitude}`;
        setCookie('user_location', geoString, 30);
        document.location.reload(true);
    }

    function error(err) {
        console.warn(`ERREUR (${err.code}): ${err.message}`);
    }

    function setCookie(name, pos, days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days*24*60*60*1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (pos || "")  + expires + "; path=/";
        }

    function getCookie (name) {
        let value = `; ${document.cookie}`;
        let parts = value.split(`; ${name}=`);
        if (parts.length === 2) {
            return parts.pop().split(';').shift(); 
        } else {
            return false;
        }
    }
})();